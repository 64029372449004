import React, { useState } from "react"
import styled from "styled-components"
import { navigate, graphql } from "gatsby"
import { registerLocale } from "react-datepicker"
import ReactDatePicker from "react-datepicker"
import { hu } from "date-fns/locale"

import Layout from "../components/layouts/v2/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import Plus from "../images/plus.svg"
import Pluses from "../images/pluses.svg"
import { useQuoteAssets } from "../hooks/quote"

registerLocale("hu", hu)

const RANGES = [
  { value: "<350e", label: "<350e Ft" },
  { value: "350-700e", label: "350-700e Ft" },
  { value: ">700e", label: ">700e Ft" },
]

const StyledContainer = styled(Container)`
  max-width: 1000px;
  padding: 1.25rem 1.5rem;
`
const StyledFormContainer = styled(StyledContainer)`
  padding: 3.5rem 1.25rem;
`
const TitleSection = styled.div`
  background: rgb(0, 174, 239);
  background: linear-gradient(
    90deg,
    rgba(0, 174, 239, 1) 0%,
    rgba(46, 49, 146, 1) 100%
  );
`
const SecondaryTitle = styled.h2`
  font-size: 1.3em;
  font-family: "Teko";
  font-weight: 500;
  margin-bottom: 0;
  white-space: pre-line;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 1.875rem;

  & div:last-child {
    order: 1;
  }

  & div:first-child {
    order: 2;
  }

  @media all and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
    gap: 100px;

    & div:last-child {
      order: 2;
    }

    & div:first-child {
      order: 1;
    }
  }
`
const ColTitle = styled.h2`
  font-size: 1.875rem;
  font-family: "Teko";
  font-weight: 700;
  margin-bottom: 0;
`
const ColDesc = styled.p`
  font-size: 0.875rem;
  font-family: "Montserrat";
  font-weight: 400;
  line-height: 1.5;
  color: #bbb;
`
const HelperText = styled(ColDesc)`
  font-size: 0.875rem;
  font-style: italic;
`
const Accent = styled.span`
  color: #00aeef;
`
const Form = styled.form`
  margin: 0 auto;
  text-align: center;
`
const Label = styled.label`
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 400;
  color: #00aeef;
  text-transform: uppercase;
`
const InputField = styled.input`
  width: 100%;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  background-color: #000;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;

  &::placeholder {
    font-size: 0.875rem;
    color: #999;
  }

  &:focus {
    outline: none;
  }

  & + .react-datepicker__close-icon {
    width: 47px;
    height: calc(100% - 8px);
    bottom: 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 0;
      font-size: 40px;
      color: #000;
      background-color: #aa3939;
    }
  }
`
const SelectField = styled.select`
  width: 100%;
  color: #fff;
  border: 1px solid #fff;
  background-color: #000;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  -webkit-appearance: none;
  appearance: none;

  &::placeholder {
    color: #999;
    font-size: 0.875rem;
  }

  &:focus {
    outline: none;
  }
`
const TextArea = styled.textarea`
  display: block;
  min-height: 200px;
  width: 100%;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  background-color: #000;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;

  &::placeholder {
    color: #999;
    font-size: 0.875rem;
  }

  &:focus {
    outline: none;
  }
`
const Error = styled.span`
  opacity: 0.6;
`
const Button = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  line-height: 0.5;
  font-size: 1.77778em;
  font-family: "Teko", sans-serif;
  color: #fff;
  padding: 15px 40px 10px 40px;
  border: 1px solid #fff;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  z-index: 1;
  margin-top: 2.77777778rem;
  background: rgb(0, 174, 239);
  background: linear-gradient(
    90deg,
    rgba(0, 174, 239, 1) 0%,
    rgba(46, 49, 146, 1) 100%
  );

  &:hover {
    color: #000;
    animation-fill-mode: forwards;

    &:before {
      animation: turn-on--outline-before 1s;
    }
    &:after {
      opacity: 1;
      animation-fill-mode: forwards;
    }
  }
`
const TextAreaContainer = styled.div`
  text-align: left;
`
const FieldGroup = styled.div`
  text-align: left;
`
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  margin-bottom: 0.75rem;
  width: 100%;
`
const DateButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  font-family: "Montserrat";
  font-size: 0.625rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #00aeef;
    border-color: #00aeef;

    & .cls-1 {
      fill: #00aeef;
    }
  }
`
const CheckboxContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto 0 auto;
  background-color: #333;
  padding: 0.625rem;
  text-align: left;

  & ${Label} {
    font-size: 1rem;
    font-family: "Montserrat";
    font-weight: 700;
    line-height: 1.5;
    text-transform: none;
    color: #00aeef;
  }

  p {
    font-size: 0.875rem;
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
  }

  a {
    color: #00aeef;
  }
`
const Checkbox = styled.input`
  margin-right: 0.5rem;
`
const DateInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 0.75rem;

  & ${InputField} {
    width: initial;
    flex: 1;
    margin-bottom: 0;
    border-right: none;

    &:only-child {
      border-right: 1px solid #fff;
    }
  }
`
const DeleteButton = styled.button`
  width: 46px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-left: none;
  outline: 0;
  background-color: #aa3939;
  cursor: pointer;

  &::after {
    content: "\\D7";
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 0;
    font-size: 40px;
    color: #000;
  }
`
const PlusIcon = styled(Plus)`
  display: flex;
  width: 12px;
  height: 12px;
  transition: all 0.2s ease-in-out;

  & .cls-1 {
    fill: #fff;
  }
`
const PlusesIcon = styled(Pluses)`
  display: flex;
  width: 15px;
  height: 15px;
  transition: all 0.2s ease-in-out;

  & .cls-1 {
    fill: #fff;
  }
`

function validate({ name, phone, companyName, email, description }, setError) {
  return new Promise((resolve, reject) => {
    const emailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    let errors = {}
    // if (!name) errors = { ...errors, name: "Név megadása kötelező!" }
    // if (!phone) errors = { ...errors, phone: "Telefonszám megadása kötelező!" }
    // if (!companyName) {
    //   errors = { ...errors, companyName: "Cégnév megadása kötelező!" }
    // }
    // if (!email) {
    //   errors = { ...errors, email: "Email cím megadása kötelező!" }
    // } else if (!emailRegexp.test(email)) {
    //   errors = { ...errors, email: "Nem megfelelő email cím!" }
    // }
    // if (!description)
    //   errors = { ...errors, description: "Leírás megadása kötelező" }
    setError(errors)

    Object.keys(errors).length === 0 ? resolve() : reject()
  })
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const DateInput = React.forwardRef(
  ({ value, onClick, onDelete, isDeleteable, visible, ...rest }, ref) => (
    <DateInputContainer>
      <InputField onClick={onClick} ref={ref} value={value} {...rest} />
      {isDeleteable && visible ? (
        <DeleteButton type="button" onClick={onDelete} />
      ) : null}
    </DateInputContainer>
  )
)

const QuotePage = () => {
  const { bg } = useQuoteAssets()
  const [state, setState] = useState({})
  const [error, setError] = useState({})
  const [dateFields, setDateFields] = useState([
    { type: "normal", id: "date-normal-0", visible: true },
    { type: "normal", id: "date-normal-1", visible: false },
    { type: "normal", id: "date-normal-2", visible: false },
    // { type: "normal", id: "date-normal-3", visible: false },
    // { type: "normal", id: "date-normal-4", visible: false },
    { type: "range", id: "date-range-0", visible: false },
    { type: "range", id: "date-range-1", visible: false },
    { type: "range", id: "date-range-2", visible: false },
    // { type: "range", id: "date-range-3", visible: false },
    // { type: "range", id: "date-range-4", visible: false },
  ])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
    // validate(state, setError).catch(() => {})
  }

  const onDelete = key => e => {
    e.preventDefault()
    setState(prevState =>
      Object.fromEntries(
        Object.entries(prevState).filter(([originalKey]) => originalKey !== key)
      )
    )
    setDateFields(prevValues =>
      prevValues.map(prevValue => ({
        ...prevValue,
        visible: prevValue.id === key ? false : prevValue.visible,
      }))
    )
  }

  const onDateAdd = type => e => {
    e.preventDefault()
    const changeId = dateFields.findIndex(
      dateField => dateField.type === type && !dateField.visible
    )
    setDateFields(prevValues =>
      prevValues.map((pv, idx) =>
        idx === changeId ? { ...pv, visible: true } : pv
      )
    )
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    // validate(state, setError)
    //   .then(() => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
    // })
    // .catch(error => {})
  }

  return (
    <Layout
      showTriangles={false}
      heroOptions={{
        title: <Accent>Ajánlatkérés</Accent>,
        bg,
        showTriangles: false,
      }}
    >
      <SEO
        title="Ajánlatkérés"
        slug="/ajanlatkeres"
        description="Rendezvényedre szabott ajánlatunkat garantáltan 24 órán belül
            megküldjük. Ügynökségünk kollégái várják megkeresésed!"
      />
      <TitleSection>
        <StyledContainer>
          <SecondaryTitle>
            KÉRJÜK OSSZA MEG VELÜNK RENDEZVÉNYE RÉSZLETEIT ÉS A MŰSORRAL
            KAPCSOLATOS ELVÁRÁSAIT,
            <br /> HOGY MEGTALÁLJUK AZ ÖNÖK SZÁMÁRA LEGIDEÁLISABB SZOLGÁLTATÁST.
          </SecondaryTitle>
        </StyledContainer>
      </TitleSection>
      <StyledFormContainer>
        <Form
          name="v2/contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          className="wow fadeIn"
          action="/koszonjuk"
        >
          <InputField
            type="hidden"
            name="form-name"
            value="kapcsolat"
            onChange={handleChange}
          />
          <Grid>
            <div>
              <ColTitle>
                Rendezvény
                <br /> Paraméterek
              </ColTitle>
              <FieldGroup style={{ marginTop: "2.5rem" }}>
                <Label htmlFor="eventType">
                  Rendezvény neve vagy típusa: *
                </Label>
                <InputField
                  type="text"
                  name="eventType"
                  id="eventType"
                  placeholder="Rendezvény neve vagy típusa"
                  onChange={handleChange}
                  required
                />
                {error.eventType && <Error>{error.eventType}</Error>}
              </FieldGroup>
              <FieldGroup style={{ display: "flex", flexDirection: "column" }}>
                <Label htmlFor="date">Dátum: *</Label>
                {dateFields.map(field =>
                  field.type === "normal" ? (
                    <div
                      key={field.id}
                      className={field.visible ? "date-visible" : "date-hidden"}
                    >
                      <ReactDatePicker
                        locale="hu"
                        className={
                          field.visible ? "date-visible" : "date-hidden"
                        }
                        name={field.id}
                        selected={state[field.id]}
                        placeholderText="Dátum"
                        onChange={date =>
                          setState(prevValues => ({
                            ...prevValues,
                            [field.id]: date,
                          }))
                        }
                        customInput={
                          <DateInput
                            visible={field.visible}
                            isDeleteable={
                              dateFields.filter(dateField => dateField.visible)
                                .length > 1
                            }
                            onDelete={onDelete(field.id)}
                          />
                        }
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        required={field.visible}
                      />
                    </div>
                  ) : (
                    <div
                      key={field.id}
                      className={field.visible ? "date-visible" : "date-hidden"}
                    >
                      <ReactDatePicker
                        locale="hu"
                        className={
                          field.visible ? "date-visible" : "date-hidden"
                        }
                        name={field.id}
                        placeholderText="Kezdő dátum - Végső dátum"
                        startDate={state[field.id]?.[0]}
                        endDate={state[field.id]?.[1]}
                        onChange={dates =>
                          setState(prevValues => {
                            return {
                              ...prevValues,
                              [field.id]: dates,
                            }
                          })
                        }
                        customInput={
                          <DateInput
                            visible={field.visible}
                            isDeleteable={
                              dateFields.filter(dateField => dateField.visible)
                                .length > 1
                            }
                            onDelete={onDelete(field.id)}
                          />
                        }
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        selectsRange
                        required={field.visible}
                      />
                    </div>
                  )
                )}
                <ButtonGroup>
                  {dateFields.filter(df => df.type === "normal" && df.visible)
                    .length < 3 ? (
                    <DateButton type="button" onClick={onDateAdd("normal")}>
                      <PlusIcon /> Új dátum
                    </DateButton>
                  ) : null}
                  {dateFields.filter(df => df.type === "range" && df.visible)
                    .length < 3 ? (
                    <DateButton type="button" onClick={onDateAdd("range")}>
                      <PlusesIcon /> Új dátumtartomány
                    </DateButton>
                  ) : null}
                </ButtonGroup>
                {error.date && <Error>{error.date}</Error>}
              </FieldGroup>
              <FieldGroup>
                <Label htmlFor="eventLocation">Rendezvény helyszíne: *</Label>
                <InputField
                  type="text"
                  name="eventLocation"
                  id="eventLocation"
                  placeholder="Rendezvény helyszíne"
                  onChange={handleChange}
                  required
                />
                {error.eventType && <Error>{error.eventType}</Error>}
              </FieldGroup>
              <FieldGroup>
                <Label htmlFor="eventBudget">Keretösszeg:</Label>
                <SelectField
                  type="text"
                  name="eventBudget"
                  id="eventBudget"
                  placeholder="Keretösszeg"
                  onChange={handleChange}
                >
                  <option key="default-key" value=""></option>
                  {RANGES.map((range, idx) => (
                    <option key={idx} value={range.value}>
                      {range.label}
                    </option>
                  ))}
                </SelectField>
                {error.eventBudget && <Error>{error.eventBudget}</Error>}
              </FieldGroup>
            </div>
            <div>
              <ColTitle>
                Kapcsolati
                <br /> adatok
              </ColTitle>
              <FieldGroup style={{ marginTop: "2.5rem" }}>
                <Label htmlFor="companyName">Cégnév: *</Label>
                <InputField
                  type="text"
                  name="companyName"
                  id="companyName"
                  placeholder="Cégnév"
                  onChange={handleChange}
                  required
                />
                {error.companyName && <Error>{error.companyName}</Error>}
              </FieldGroup>
              <FieldGroup>
                <Label htmlFor="name">Kapcsolattartó neve: *</Label>
                <InputField
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Kapcsolattartó neve"
                  onChange={handleChange}
                  required
                />
                {error.name && <Error>{error.name}</Error>}
              </FieldGroup>
              <FieldGroup>
                <Label htmlFor="email">E-mail: *</Label>
                <InputField
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-mail"
                  onChange={handleChange}
                  required
                />
                {error.email && <Error>{error.email}</Error>}
              </FieldGroup>
              <FieldGroup>
                <Label htmlFor="phone">Telefonszám: *</Label>
                <InputField
                  type="phone"
                  name="phone"
                  id="phone"
                  placeholder="Telefonszám"
                  onChange={handleChange}
                  required
                />
                {error.phone && <Error>{error.phone}</Error>}
              </FieldGroup>

              <HelperText>
                A megadott adatokat kizárólag ajánlatadással kapcsolatban
                használjük fel.
              </HelperText>
            </div>
          </Grid>

          <TextAreaContainer>
            <ColTitle>Műsorral kapcsolatos elvárások</ColTitle>
            <ColDesc>
              Mint például: kiszemelt műsortípusok, műsor hossz, rendelkezésre
              álló terület(ek) méretei, jellege (színpad, aszfalt, térkő, fű
              stb), belmagasság, foglalkozatás? stb.{" "}
            </ColDesc>
            <TextArea
              placeholder="Rendezvény rövid bemutatása"
              name="description"
              onChange={handleChange}
            />
            {error.description && <Error>{error.description}</Error>}
          </TextAreaContainer>
          <CheckboxContainer>
            <Label>
              <Checkbox
                type="checkbox"
                name="marketing"
                id="marketing"
                onChange={handleChange}
              />
              Szeretne értesülni a legújabb műsorainkról és megjelenéseinkről?
            </Label>
            <p>
              Iratkozzon fel havi rendszerességű hírleveleinkre, hogy elsők közt
              értesülhessen az ország legújabb showműsorairól, továbbá olyan
              exkluzív kép-és videóanyagokhoz jusson hozzá rendezvényeken való
              megjelenéseinkről, amiket sehol máshol nem teszünk közzé. Csak
              releváns információkat fogunk küldeni Önnek és soha nem adjuk el
              az adatait harmadik félnek. A hírlevélről később bármikor
              leiratkozhat.{" "}
              <a
                href="/adatvedelmi-iranyelvek"
                rel="noreferrer noopener"
                target="_blank"
              >
                Tekintse meg teljes adatvédelmi szabályzatunk.
              </a>
            </p>
          </CheckboxContainer>
          <Button type="submit" disabled={Object.keys(error).length > 0}>
            Beküldés
          </Button>
        </Form>
      </StyledFormContainer>
    </Layout>
  )
}

export default QuotePage
