/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Header from "../../header"
import Footer from "./Footer"
import Partners from "../../sections/Partners"
import Hero from "../../sections/Hero"
import VideoHero from "../../sections/VideoHero"
import { useAboutAssets } from "../../../hooks/about"
import CookieConsent from "../../CookieConsent"
import Up from "../../Up"
import { useSiteMeta } from "../../../hooks/siteMeta"

import "react-datepicker/dist/react-datepicker.css"

const Main = styled.main`
  background-color: #000;
  padding: 0 0 100px 0;
`

const Layout = ({
  children,
  homepage,
  showPartners = true,
  isVideo = false,
  heroOptions = {},
}) => {
  const { title } = useSiteMeta()
  const { bg } = useAboutAssets()
  return (
    <>
      <CookieConsent />

      <Header siteTitle={title} />
      {isVideo ? (
        <VideoHero homepage={homepage} {...heroOptions} />
      ) : (
        <Hero homepage={homepage} {...heroOptions} />
      )}
      <Main bg={bg}>
        {children}
        {showPartners && <Partners />}
        <Up />
      </Main>

      <Footer color={"#6c9fff"} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
